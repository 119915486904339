var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('h3',{staticClass:"mt-5"},[_vm._v("Visão Geral")]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-3"},[_c('v-card-text',{staticClass:"pa-2"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h5',[_vm._v("Competência Anterior")])])],1),_c('v-simple-table',{staticClass:"slim-table",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("SE")]),_c('th',[_vm._v("LD")]),_c('th',[_vm._v("AL")]),_c('th',[_vm._v("Aprovação Alertas")]),_c('th',[_vm._v("SE")]),_c('th',[_vm._v("LD")]),_c('th',[_vm._v("AL")])])]),(Object.keys(_vm.dadosTabela).length > 0)?_c('tbody',[_vm._l((_vm.linhasTabelaPt1),function(row){return _c('tr',{key:row.value},[_c('td',[_vm._v(_vm._s(row.text))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.dadosTabela.se[row.value] || 0))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.dadosTabela.ld[row.value] || 0))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.dadosTabela.al[row.value] || 0))+" ")]),_c('td',{staticStyle:{"width":"330px !important"}},[_vm._l((['se', 'ld', 'al']),function(base){return _c('div',{key:base,staticClass:"d-inline-flex"},[(!['reclamacao', 'clientes'].includes(row.value))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticStyle:{"padding-top":"5px"},attrs:{"color":{
                                  VALIDO: 'success',
                                  ALERTA: 'warning',
                                  ERRO_CRITICO: 'error',
                                }[
                                  _vm.dadosValidacoesVisaoGeral[base][
                                    ("tipo_validacao_" + (row.value))
                                  ]
                                ]}},[_vm._v(" "+_vm._s({ VALIDO: "mdi-check-circle-outline", ALERTA: "mdi-alert-circle-outline", ERRO_CRITICO: "mdi-close-circle", }[ _vm.dadosValidacoesVisaoGeral[base][ ("tipo_validacao_" + (row.value)) ] ] || "mdi-check-circle-outline")+" ")])],1)]}}],null,true)},[_c('span',{class:{
                              VALIDO: 'success--text font-weight-bold',
                              ALERTA: 'warning--text font-weight-bold',
                              ERRO_CRITICO: 'error--text font-weight-bold',
                            }[
                              _vm.dadosValidacoesVisaoGeral[base][
                                ("tipo_validacao_" + (row.value))
                              ]
                            ]},[_vm._v(" "+_vm._s({ VALIDO: " ", ALERTA: "ALERTA:", ERRO_CRITICO: "ERRO CRÍTICO:", }[ _vm.dadosValidacoesVisaoGeral[base][ ("tipo_validacao_" + (row.value)) ] ] || "NA")+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.dadosValidacoesVisaoGeral[base][ ("validacao_" + (row.value)) ])+" ")])]):_vm._e(),(!['reclamacao', 'clientes'].includes(row.value))?_c('v-checkbox',{staticClass:"ml-1 mr-3 my-0",attrs:{"disabled":_vm.dadosValidacoesVisaoGeral[base][
                            ("validacao_" + (row.value))
                          ] == undefined ||
                            _vm.dadosValidacoesVisaoGeral[base][
                              ("tipo_validacao_" + (row.value))
                            ] == 'VALIDO' ||
                            (!_vm.authorizedCompanies.includes(
                              _vm.$store.getters.getCompanyId
                            ) &&
                              !_vm.isAdmin),"hide-details":"","label":base.toUpperCase(),"indeterminate":_vm.dadosValidacoesVisaoGeral[base][
                            ("aprovacao_validacao_" + (row.value))
                          ] == undefined},model:{value:(
                          _vm.dadosValidacoesVisaoGeral[base][
                            ("aprovacao_validacao_" + (row.value))
                          ]
                        ),callback:function ($$v) {_vm.$set(_vm.dadosValidacoesVisaoGeral[base], 
                            ("aprovacao_validacao_" + (row.value))
                          , $$v)},expression:"\n                          dadosValidacoesVisaoGeral[base][\n                            `aprovacao_validacao_${row.value}`\n                          ]\n                        "}}):_vm._e()],1)}),(['reclamacao', 'clientes'].includes(row.value))?_c('span',[_vm._v(" - ")]):_vm._e()],2),_c('td',{class:_vm.classSe(row)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.dadosTabela.se_anterior[row.value] == "NA" ? "NA" : (((_vm.dadosTabela.se_anterior[row.value] || 0) - (_vm.dadosTabela.se[row.value] || 0)) * -1)))+" ")]),_c('td',{class:_vm.classLd(row)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.dadosTabela.ld_anterior[row.value] == "NA" ? "NA" : (((_vm.dadosTabela.ld_anterior[row.value] || 0) - (_vm.dadosTabela.ld[row.value] || 0)) * -1)))+" ")]),_c('td',{class:_vm.classAl(row)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.dadosTabela.al_anterior[row.value] == "NA" ? "NA" : (((_vm.dadosTabela.al_anterior[row.value] || 0) - (_vm.dadosTabela.al[row.value] || 0)) * -1)))+" ")])])}),_vm._l((_vm.linhasTabelaPt2),function(row){return _c('tr',{key:row.value},[_c('td',[_vm._v(_vm._s(row.text))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.dadosTabela.se[row.value] || 0))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.dadosTabela.ld[row.value] || 0))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.dadosTabela.al[row.value] || 0))+" ")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")])])})],2):_vm._e()]},proxy:true}])}),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getDadosDialogDetalhes}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"success","disabled":!_vm.authorizedCompanies.includes(
                    _vm.$store.getters.getCompanyId
                  ) && !_vm.isAdmin},on:{"click":_vm.salvaAprovacaoAlertas}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('chart-clientes-tecnicos',{attrs:{"competencias":_vm.competencias,"dadosAlimentadores":_vm.dadosGraficosClientesAndReclamacao.al || {},"dadosSubestacoes":_vm.dadosGraficosClientesAndReclamacao.se || {}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('chart-reclamacao-tecnicos',{attrs:{"competencias":_vm.competencias,"dadosAlimentadores":_vm.dadosGraficosClientesAndReclamacao.al || {},"dadosSubestacoes":_vm.dadosGraficosClientesAndReclamacao.se || {}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('chart-pareto-reclamacao-alimentador-tecnicos',{attrs:{"dados":_vm.dadosParetoReclamacaoByIdentificacao.al,"alimentadores":_vm.alimentadores}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('chart-pareto-reclamacao-subestacao-tecnicos',{attrs:{"dados":_vm.dadosParetoReclamacaoByIdentificacao.se,"subestacoes":_vm.subestacoes}})],1)],1),_c('tecnicos-dashboard-dialog-detalhes',{ref:"tecnicosDashboardDialogDetalhes",attrs:{"competencia":_vm.competencia}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }