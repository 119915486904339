<template>
  <span>
    <h3 class="mt-5">Visão Geral</h3>

    <v-row dense>
      <v-col cols="12">
        <v-card class="mt-3">
          <v-card-text class="pa-2">
            <v-row justify="end">
              <v-col cols="auto">
                <h5>Competência Anterior</h5>
              </v-col>
            </v-row>
            <v-simple-table class="slim-table" dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th></th>
                    <th>SE</th>
                    <th>LD</th>
                    <th>AL</th>
                    <th>Aprovação Alertas</th>
                    <!-- Competencia anterior -->
                    <th>SE</th>
                    <th>LD</th>
                    <th>AL</th>
                  </tr>
                </thead>
                <tbody v-if="Object.keys(dadosTabela).length > 0">
                  <tr v-for="row in linhasTabelaPt1" :key="row.value">
                    <td>{{ row.text }}</td>
                    <td>
                      {{
                        dadosTabela.se[row.value] ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>
                      {{
                        dadosTabela.ld[row.value] ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>
                      {{
                        dadosTabela.al[row.value] ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td style="width: 330px !important">
                      <div
                        v-for="base in ['se', 'ld', 'al']"
                        :key="base"
                        class="d-inline-flex"
                      >
                        <v-tooltip
                          bottom
                          v-if="!['reclamacao', 'clientes'].includes(row.value)"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-icon
                                style="padding-top: 5px;"
                                :color="
                                  {
                                    VALIDO: 'success',
                                    ALERTA: 'warning',
                                    ERRO_CRITICO: 'error',
                                  }[
                                    dadosValidacoesVisaoGeral[base][
                                      `tipo_validacao_${row.value}`
                                    ]
                                  ]
                                "
                              >
                                {{
                                  {
                                    VALIDO: "mdi-check-circle-outline",
                                    ALERTA: "mdi-alert-circle-outline",
                                    ERRO_CRITICO: "mdi-close-circle",
                                  }[
                                    dadosValidacoesVisaoGeral[base][
                                      `tipo_validacao_${row.value}`
                                    ]
                                  ] || "mdi-check-circle-outline"
                                }}
                              </v-icon>
                            </span>
                          </template>
                          <span
                            :class="
                              {
                                VALIDO: 'success--text font-weight-bold',
                                ALERTA: 'warning--text font-weight-bold',
                                ERRO_CRITICO: 'error--text font-weight-bold',
                              }[
                                dadosValidacoesVisaoGeral[base][
                                  `tipo_validacao_${row.value}`
                                ]
                              ]
                            "
                          >
                            {{
                              {
                                VALIDO: " ",
                                ALERTA: "ALERTA:",
                                ERRO_CRITICO: "ERRO CRÍTICO:",
                              }[
                                dadosValidacoesVisaoGeral[base][
                                  `tipo_validacao_${row.value}`
                                ]
                              ] || "NA"
                            }}
                          </span>
                          <span>
                            {{
                              dadosValidacoesVisaoGeral[base][
                                `validacao_${row.value}`
                              ]
                            }}
                          </span>
                        </v-tooltip>
                        <v-checkbox
                          v-if="!['reclamacao', 'clientes'].includes(row.value)"
                          :disabled="
                            dadosValidacoesVisaoGeral[base][
                              `validacao_${row.value}`
                            ] == undefined ||
                              dadosValidacoesVisaoGeral[base][
                                `tipo_validacao_${row.value}`
                              ] == 'VALIDO' ||
                              (!authorizedCompanies.includes(
                                $store.getters.getCompanyId
                              ) &&
                                !isAdmin)
                          "
                          class="ml-1 mr-3 my-0"
                          hide-details
                          :label="base.toUpperCase()"
                          :indeterminate="
                            dadosValidacoesVisaoGeral[base][
                              `aprovacao_validacao_${row.value}`
                            ] == undefined
                          "
                          v-model="
                            dadosValidacoesVisaoGeral[base][
                              `aprovacao_validacao_${row.value}`
                            ]
                          "
                        />
                      </div>
                      <span
                        v-if="['reclamacao', 'clientes'].includes(row.value)"
                      >
                        -
                      </span>
                    </td>
                    <td :class="classSe(row)">
                      {{
                        dadosTabela.se_anterior[row.value] == "NA"
                          ? "NA"
                          : (((dadosTabela.se_anterior[row.value] || 0) -
                              (dadosTabela.se[row.value] || 0)) *
                              -1)
                            | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td :class="classLd(row)">
                      {{
                        dadosTabela.ld_anterior[row.value] == "NA"
                          ? "NA"
                          : (((dadosTabela.ld_anterior[row.value] || 0) -
                              (dadosTabela.ld[row.value] || 0)) *
                              -1)
                            | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td :class="classAl(row)">
                      {{
                        dadosTabela.al_anterior[row.value] == "NA"
                          ? "NA"
                          : (((dadosTabela.al_anterior[row.value] || 0) -
                              (dadosTabela.al[row.value] || 0)) *
                              -1)
                            | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                  </tr>
                  <tr v-for="row in linhasTabelaPt2" :key="row.value">
                    <td>{{ row.text }}</td>
                    <td>
                      {{
                        dadosTabela.se[row.value] ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>
                      {{
                        dadosTabela.ld[row.value] ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>
                      {{
                        dadosTabela.al[row.value] ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-row justify="end">
              <v-col cols="1">
                <v-btn color="primary" @click="getDadosDialogDetalhes">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  color="success"
                  @click="salvaAprovacaoAlertas"
                  :disabled="
                    !authorizedCompanies.includes(
                      $store.getters.getCompanyId
                    ) && !isAdmin
                  "
                >
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="6">
        <chart-clientes-tecnicos
          :competencias="competencias"
          :dadosAlimentadores="dadosGraficosClientesAndReclamacao.al || {}"
          :dadosSubestacoes="dadosGraficosClientesAndReclamacao.se || {}"
        />
      </v-col>
      <v-col cols="6">
        <chart-reclamacao-tecnicos
          :competencias="competencias"
          :dadosAlimentadores="dadosGraficosClientesAndReclamacao.al || {}"
          :dadosSubestacoes="dadosGraficosClientesAndReclamacao.se || {}"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <chart-pareto-reclamacao-alimentador-tecnicos
          :dados="dadosParetoReclamacaoByIdentificacao.al"
          :alimentadores="alimentadores"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <chart-pareto-reclamacao-subestacao-tecnicos
          :dados="dadosParetoReclamacaoByIdentificacao.se"
          :subestacoes="subestacoes"
        />
      </v-col>
    </v-row>

    <tecnicos-dashboard-dialog-detalhes
      :competencia="competencia"
      ref="tecnicosDashboardDialogDetalhes"
    />
  </span>
</template>

<script>
  import PainelTecnicosService from "@/services/PainelTecnicosService.js";

  import dayjs from "dayjs";
  import { generateMonthlyDateRangeArray } from "@/utils/dateUtils.js";
  import UsersService from "@/services/UsersService.js";

  export default {
    name: "TecnicosDashboard",
    components: {
      ChartClientesTecnicos: () =>
        import("@/components/validacoes/Charts/ChartClientesTecnicos.vue"),
      ChartReclamacaoTecnicos: () =>
        import("@/components/validacoes/Charts/ChartReclamacaoTecnicos.vue"),
      ChartParetoReclamacaoAlimentadorTecnicos: () =>
        import(
          "@/components/validacoes/Charts/ChartParetoReclamacaoAlimentadorTecnicos.vue"
        ),
      ChartParetoReclamacaoSubestacaoTecnicos: () =>
        import(
          "@/components/validacoes/Charts/ChartParetoReclamacaoSubestacaoTecnicos.vue"
        ),
      TecnicosDashboardDialogDetalhes: () =>
        import("@/components/validacoes/TecnicosDashboardDialogDetalhes.vue"),
    },
    props: {
      competencia: {
        type: String,
        required: true,
      },
      loadingDados: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      isAdmin() {
        return this.$store.getters.getUserInfo.admin;
      },
    },
    data: () => ({
      linhasTabelaPt1: [
        { text: "Quantidade", value: "quantidade" },
        { text: "Inspeção Visual", value: "inspecao_visual" },
        { text: "Inspeção Termográfica", value: "inspecao_termografica" },
        { text: "Manutenção Preventiva", value: "manutencao_preventiva" },
        { text: "Manutenção Corretiva", value: "manutencao_corretiva" },
        { text: "Clientes", value: "clientes" },
        { text: "Reclamação", value: "reclamacao" },
      ],
      linhasTabelaPt2: [
        { text: "Carregamento 0 - 50", value: "carregamento_0_50" },
        { text: "Carregamento 50 - 90", value: "carregamento_50_90" },
        { text: "Carregamento 90 - 100", value: "carregamento_90_100" },
        { text: "Carregamento > 100", value: "carregamento_maior_100" },
      ],
      dadosTabela: {},
      dadosValidacoesVisaoGeral: {},
      competencias: [],
      dadosGraficosClientesAndReclamacao: {},
      alimentadores: [],
      subestacoes: [],
      dadosParetoReclamacaoByIdentificacao: {},
      authorizedCompanies: [],
    }),
    mounted() {
      this.getDados(this.competencia);
    },
    methods: {
      getDados(competencia = this.competencia) {
        this.$emit("updateLoadingDados", true);

        Promise.all([
          this.getDadosTabelaVisaoGeral(competencia),
          this.getDadosValidacoesVisaoGeral(competencia),
          this.getDadosGraficosClientesAndReclamacao(competencia),
          this.getReclamacaoByIdentificacao(competencia),
          this.getAuthorizedCompanies(),
        ])
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro recuperar dados.", "", {
              position: "topRight",
            });
          })
          .finally(() => this.$emit("updateLoadingDados", false));
      },
      getDadosTabelaVisaoGeral(competencia) {
        return PainelTecnicosService.getDadosTabelaVisaoGeral(competencia)
          .then((res) => {
            this.dadosTabela = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosValidacoesVisaoGeral(competencia) {
        return PainelTecnicosService.getDadosValidacoesVisaoGeral(competencia)
          .then((res) => {
            this.dadosValidacoesVisaoGeral = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosDialogDetalhes() {
        this.$refs.tecnicosDashboardDialogDetalhes.getDadosNovosCodigos(
          this.competencia
        );
      },
      getDadosGraficosClientesAndReclamacao(competencia) {
        return PainelTecnicosService.getClientesAndReclamacaoGroupByCompetencia(
          dayjs(competencia)
            .subtract(12, "month")
            .format("YYYY-MM"),
          competencia
        )
          .then((res) => {
            this.dadosGraficosClientesAndReclamacao = res;

            this.competencias = generateMonthlyDateRangeArray(
              dayjs(competencia)
                .subtract(12, "month")
                .format("YYYY-MM"),
              competencia,
              "YYYY-MM"
            );
          })
          .catch((err) => {
            throw err;
          });
      },
      getReclamacaoByIdentificacao(competencia) {
        return PainelTecnicosService.getReclamacaoByIdentificacao(competencia)
          .then((res) => {
            this.dadosParetoReclamacaoByIdentificacao = res;

            this.alimentadores = Object.keys(
              this.dadosParetoReclamacaoByIdentificacao.al
            );
            this.subestacoes = Object.keys(
              this.dadosParetoReclamacaoByIdentificacao.se
            );
          })
          .catch((err) => {
            throw err;
          });
      },
      salvaAprovacaoAlertas() {
        return PainelTecnicosService.salvarAprovacaoValidacoesVisaoGeral(
          this.dadosValidacoesVisaoGeral
        )
          .then((res) => {
            this.$toast.success("Salvado com sucesso.", "", {
              position: "topRight",
            });
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro ao salvar aprovações.", "", {
              position: "topRight",
            });
          });
      },
      classLd(row) {
        let classes = [];
        if (
          this.dadosValidacoesVisaoGeral.ld[`tipo_validacao_${row.value}`] ==
          "ALERTA"
        ) {
          classes.push("error--text font-weight-bold");
        }

        if (
          this.dadosTabela.ld_anterior[row.value] != "NA" &&
          ((this.dadosTabela.ld_anterior[row.value] || 0) -
            (this.dadosTabela.ld[row.value] || 0)) *
            -1 >=
            0
        ) {
          classes.push("success--text font-weight-bold");
        } else if (this.dadosTabela.ld[row.value] != "NA") {
          classes.push("error--text font-weight-bold");
        }
        return classes;
      },
      classAl(row) {
        let classes = [];
        if (
          this.dadosValidacoesVisaoGeral.al[`tipo_validacao_${row.value}`] ==
          "ALERTA"
        ) {
          classes.push("error--text font-weight-bold");
        }

        if (
          this.dadosTabela.al_anterior[row.value] != "NA" &&
          ((this.dadosTabela.al_anterior[row.value] || 0) -
            (this.dadosTabela.al[row.value] || 0)) *
            -1 >=
            0
        ) {
          classes.push("success--text font-weight-bold");
        } else if (this.dadosTabela.al[row.value] != "NA") {
          classes.push("error--text font-weight-bold");
        }
        return classes;
      },
      classSe(row) {
        let classes = [];
        if (
          this.dadosValidacoesVisaoGeral.se[`tipo_validacao_${row.value}`] ==
          "ALERTA"
        ) {
          classes.push("error--text font-weight-bold");
        }

        if (
          this.dadosTabela.se_anterior[row.value] != "NA" &&
          ((this.dadosTabela.se_anterior[row.value] || 0) -
            (this.dadosTabela.se[row.value] || 0)) *
            -1 >=
            0
        ) {
          classes.push("success--text font-weight-bold");
        } else if (this.dadosTabela.se[row.value] != "NA") {
          classes.push("error--text font-weight-bold");
        }
        return classes;
      },
      getAuthorizedCompanies() {
        UsersService.getAuthorizedCompaniesValidation()
          .then((res) => {
            this.authorizedCompanies = res;
          })
          .catch((err) => {
            this.$toast.error("Erro no download do arquivo.", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          });
      },
    },
  };
</script>

<style>
  .slim-table table tbody tr td {
    font-size: 12px !important;
    padding: 0px 8px !important;
    text-align: end !important;
  }
  .slim-table table thead tr th {
    font-size: 12px !important;
    height: 25px !important;
    text-align: end !important;
    font-weight: 500 !important;
  }
</style>
